import { createStore } from 'redux'

const initialState = {
  sidebarShow: 'false',
  //org_code : '3730102',
  org_code : (localStorage.getItem('org_code') == undefined ?(''):(localStorage.getItem('org_code'))),
  workspace_id :  (localStorage.getItem('workspace_id') == undefined ?(''):(localStorage.getItem('workspace_id'))),
  role : 'survey',
  //annual : '2564'
  annual : (localStorage.getItem('annual') == undefined ?('2564'):(localStorage.getItem('annual'))),
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store