import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';


import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));


class App extends Component {

  

  render() {



    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login"  render={props => <Login {...props}/>} />
              <Route exact path="/register"  render={props => <Register {...props}/>} />
              <Route path="/" name="Login" render={props => <TheLayout {...props}/>} />

            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
